export default {
    characterCreation: {
        "title": "Character Creation",
        "character": "Character",
        "name": "Name",
        "description": "Character Description",
        "hints": "Hints",
        "reverseHints": "Reverse Hints",
        "image": "Image",
        "confirmCharacter": "Confirm Character",
        "generatedImage": "Generated Image",
        "selectImage": "Select an Image for the Character",
        "generateImage": "Generate Image", // Modified here
        "imageGeneration": "Image Generation",
        "cameraEffects": "Camera Effects",
        "moveLeft": "Move Left",
        "moveRight": "Move Right",
        "zoomIn": "Zoom In",
        "zoomOut": "Zoom Out",
        "generatedPicture": "Generated Picture",
        "selectBackground": "Select an Image as the Background for the Current Page",
        "page": "Page",
        "repaginate": "Repaginate",
        "composeVideo": "Compose Video",
        "picture": "Page",
        "addPage": "Add Page",
        "copy": "Copy",
        "delete": "Delete",
        "characterDescription": "Describe the character's appearance, for example: xxxx",
        "undesiredAppearance": "Describe features you do not want to appear on this character. For example, if you want a character with long hair, enter 'short hair' here.",
        "desiredElements": "Describe elements you want in the image, story, etc.",
        "undesiredElements": "Describe elements you do not want in the image. For example, if you want an image of an empty street, you can enter: humans",
        "addParagraph": "Add a paragraph of text to this page.",
        "confirmVideoType": "Confirm Video Type",
        "selectVideoType": "Select Your Video Type",
        "visualStyle": "Visual Style",
        "videoAspectRatio": "Video Aspect Ratio",
        "fillRequiredFields": "Please fill in all required fields first",
        "confirmDeleteSegment": "Are you sure you want to delete this segment?",
        "segmentDeletionWarning": "Deleting a segment is irreversible. Please proceed with caution.",
        "confirmRepaginate": "Are you sure you want to repaginate?",
        "repaginateWarning": "Repaginating will regenerate all page content, and the current edits will not be preserved.",
        "videoSynthesisInProgress": "in progress",
        "cancel": "Cancel",
        "confirm": "Confirm",
        "generationComplete": "complete",
        "contentGenerationInProgress": "in progress"
    }
};
