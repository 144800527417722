export default {
    characterCreation: {
        "title": "形象创造",
        "character": "角色",
        "name": "名字",
        "description": "人物介绍",
        "hints": "提示词",
        "reverseHints": "反向提示词",
        "image": "图片",
        "confirmCharacter": "确认角色形象",
        "generatedImage": "生成的形象",
        "selectImage": "选择一张图片作为当前选中角色的形象",
        "generateImage": "生成图片", // 修改这里
        "imageGeneration": "图片生成",
        "cameraEffects": "镜头效果",
        "moveLeft": "左移",
        "moveRight": "右移",
        "zoomIn": "放大",
        "zoomOut": "缩小",
        "generatedPicture": "生成的图片",
        "selectBackground": "选择一张图片作为当前页的背景图",
        "page": "页",
        "repaginate": "重新分页",
        "composeVideo": "合成视频",
        "picture": "张",
        "addPage": "添加页面",
        "copy": "复制",
        "delete": "删除",
        "characterDescription": "描述角色的外形，例如：xxxx",
        "undesiredAppearance": "描述你不希望在这名角色身上出现的外形特征，例如希望生成长发角色，则在此输入“短发”",
        "desiredElements": "描述你图片中的元素，如需生成指定角色的图片，请在角色列表中添加",
        "undesiredElements": "描述你不希望在图片中的元素，例如你想要一张描绘空无一人街道的图片，可以输入：人类",
        "addParagraph": "为此页面添加一段文本",
        "confirmVideoType": "确认视频类型",
        "selectVideoType": "选择你的视频类型",
        "visualStyle": "视觉风格",
        "videoAspectRatio": "视频比例",
        "fillRequiredFields": "请先填写完必要的内容",
        "confirmDeleteSegment": "确定要删除该片段吗？",
        "segmentDeletionWarning": "片段删除后不可恢复，请谨慎操作",
        "confirmRepaginate": "确定要重新分页吗？",
        "repaginateWarning": "重新生成全部页面内容，当前编辑的内容将不会被保留",
        "videoSynthesisInProgress": "视频合成中",
        "cancel": "取消",
        "confirm": "确认",
        "generationComplete": "生成完毕",
        "contentGenerationInProgress": "内容生成中"
    }
};