<template>
  <!-- Loading Overlay -->
  <v-overlay v-if="loading" :value="loading" opacity="0.7" color="black">
    <v-row align="center" justify="center">
      <v-progress-circular v-if="!normal" :rotate="-90" :size="200" :width="15" :value="progress" color="primary">
        <div class=" white--text d-flex flex-column align-center justify-center">
          <div class=" caption">{{ loadingText }}</div>
          <div class="text-h4">{{ progress }}%</div>
        </div>

      </v-progress-circular>
      <v-progress-circular v-else :size="150" :width="7" color="primary" indeterminate></v-progress-circular>
    </v-row>
  </v-overlay>
</template>

<script>
export default {
  name: 'LoadingBar',
  data() {
    return {
      loading: false,     // 控制遮罩显示/隐藏
      loadingText: "",  // 百分比文本
      progress: 0,
      startTimer: '',
      normal: false
    };
  },
  methods: {

    hasLoading() {
      return this.loading
    },
    // 通过调用此方法来隐藏加载遮罩
    hideLoading() {
      if (this.normal) {
        this.normal = false
        this.loading = false;
      } else {
        clearInterval(this.startTimer);
        this.loadingText = this.$t('characterCreation.generationComplete')
        this.progress = 96
        const interval = setInterval(() => {
          this.progress += 1;
          // this.loadingText = `${progress}%`; // 更新百分比文本

          if (this.progress >= 100) {

            this.loading = false;
            clearInterval(interval);

          }
        }, 150);
      }
    },
    // // 通过调用此方法来显示加载遮罩
    showLoading(time, text) {
      if (time) {
        this.progress = 0;
        if(text) {
          this.loadingText = text
        } else {
          this.loadingText = this.$t('characterCreation.contentGenerationInProgress')
        }

        this.loading = true;
        // let timer = Math.trunc(Math.round(time / 200 * 10) / 10);

        this.startTimer = this.progressInterval(time); // 更新间隔，实际情况根据需求调整
      } else {
        this.normal = true
        this.loading = true;
      }
    },
    progressInterval(time) {
      return setInterval(() => {
        this.progress += 1;

        if (this.progress >= 75) {
          clearInterval(this.startTimer);
          this.startTimer = this.progressInterval(time + 200)
        }
        if (this.progress >= 94) {

          clearInterval(this.startTimer);

        }
      }, time); // 更新间隔，实际情况根据需求调整
    }
  },
};
</script>

<style scoped>
::v-deep .v-progress-circular__underlay {
  stroke: white;
}

::v-deep .v-progress-circular__overlay {
  stroke-linecap: round;
}
</style>
