
<template>
  <v-dialog v-model="dialog" max-width="360" persistent>
    <v-card color="#3D3D3D">
      <div class="pa-6">

        <VAvatar color="#FEE4E2" size="3rem">
          <VIcon color="#D92D20" size="1.5rem">{{ icons.mdiAlertCircleOutline }}</VIcon>
        </VAvatar>
      </div>
      <v-card-text>
        <div v-if="message.slot" v-html="message.slot"></div>
        <div v-else>
          <div class="title white--text">{{ message.title }}</div>
          <div class="caption mt-1">{{ message.subTitle }}</div>
        </div>

      </v-card-text>
      <v-card-actions class="pa-6">
        <VRow no-gutters>
          <v-col><v-btn color="2D2D2D" block elevation="1" class="mr-2" @click="rejectPromise">{{ $t('characterCreation.cancel') }}</v-btn></v-col>
          <v-col><v-btn color="#D45147" block elevation="1" class="ml-2" @click="resolvePromise">{{ $t('characterCreation.confirm') }}</v-btn></v-col>
        </VRow>


      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiAlertCircleOutline } from '@mdi/js'
export default {
  name: 'ConfirmBox',
  data() {
    return {
      icons: {
        mdiAlertCircleOutline
      },
      message: {

      },
      dialog: false,
      promiseResolve: null,
      promiseReject: null
    };
  },
  methods: {
    showConfirmDialog(message) {
      this.message = message
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.promiseResolve = resolve;
        this.promiseReject = reject;
      });
    },
    resolvePromise() {
      this.dialog = false;
      if (this.promiseResolve) {
        this.promiseResolve('Confirmed');
      }
    },
    rejectPromise() {
      this.dialog = false;
      if (this.promiseReject) {
        this.promiseReject('Canceled');
      }
    }
  }
};
</script>



